import React from 'react';
import { 
  Container, 
  Typography, 
  Box,
  Divider
} from '@mui/material';
import { ArticleSubmission, ArticleGrid } from '../components/Article';

const ArticlesPage: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom 
          align="center"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            mb: 2
          }}
        >
          R1 News & Articles
        </Typography>
        <Typography 
          variant="h6" 
          align="center" 
          color="text.secondary"
          sx={{ mb: 2 }}
        >
          Discover and share the latest news, reviews, and articles about the Rabbit R1
        </Typography>
      </Box>
      
      <ArticleSubmission />
      
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Latest Articles
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <ArticleGrid />
      </Box>
    </Container>
  );
};

export default ArticlesPage;
