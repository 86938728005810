import { createTheme, Theme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

// Purple sapphire stone color palette
const purpleColors = {
  primary: {
    light: '#9c64a6',
    main: '#673ab7',
    dark: '#482880',
  },
  secondary: {
    light: '#b39ddb',
    main: '#7e57c2',
    dark: '#5e35b1',
  },
};

// Create theme function that handles both light and dark modes
export const createAppTheme = (mode: PaletteMode): Theme => {
  return createTheme({
    palette: {
      mode,
      primary: purpleColors.primary,
      secondary: purpleColors.secondary,
      background: {
        default: mode === 'dark' ? '#121212' : '#f5f5f5',
        paper: mode === 'dark' ? '#1e1e1e' : '#ffffff',
      },
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#333333',
        secondary: mode === 'dark' ? '#bdbdbd' : '#757575',
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontWeight: 600,
        fontSize: '2.5rem',
      },
      h2: {
        fontWeight: 600,
        fontSize: '2rem',
      },
      h3: {
        fontWeight: 600,
        fontSize: '1.75rem',
      },
      h4: {
        fontWeight: 600,
        fontSize: '1.5rem',
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.25rem',
      },
      h6: {
        fontWeight: 600,
        fontSize: '1rem',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: 'none',
            fontWeight: 600,
          },
          containedPrimary: {
            boxShadow: mode === 'dark' ? '0 2px 6px rgba(156, 100, 166, 0.4)' : '0 2px 6px rgba(103, 58, 183, 0.2)',
            '&:hover': {
              boxShadow: mode === 'dark' ? '0 4px 10px rgba(156, 100, 166, 0.6)' : '0 4px 10px rgba(103, 58, 183, 0.3)',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: mode === 'dark' 
              ? '0 4px 12px rgba(0, 0, 0, 0.4)' 
              : '0 4px 12px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: mode === 'dark' 
                ? '0 8px 24px rgba(0, 0, 0, 0.6)' 
                : '0 8px 24px rgba(0, 0, 0, 0.15)',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderBottom: `1px solid ${mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
          },
        },
      },
    },
  });
};

export default createAppTheme;
