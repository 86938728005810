import React, { ReactNode, useState, useEffect } from 'react';
import { Box, Container, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [showWelcome, setShowWelcome] = useState<boolean>(false);
  
  useEffect(() => {
    // Check if this is the first time the user is visiting
    const hasVisited = localStorage.getItem('r1memories_visited');
    
    if (!hasVisited) {
      setShowWelcome(true);
      localStorage.setItem('r1memories_visited', 'true');
    }
  }, []);
  
  const handleCloseWelcome = () => {
    setShowWelcome(false);
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: (theme) => 
          theme.palette.mode === 'dark' ? '#121212' : '#f5f5f5',
      }}
    >
      <Header />
      
      <Container component="main" sx={{ 
        mt: 4, 
        mb: 4, 
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
      }}>
        {children}
      </Container>
      
      <Footer />
      
      {/* First-time user welcome dialog */}
      <Dialog
        open={showWelcome}
        onClose={handleCloseWelcome}
        aria-labelledby="welcome-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="welcome-dialog-title">
          Welcome to r1memories!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            This is a community site for sharing and discovering memories about the Rabbit R1 device.
            Here's how to use the site:
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            1. Share Your Memories
          </Typography>
          <Typography variant="body2" paragraph>
            Use the upload bar to share text posts or links about the Rabbit R1. 
            Add hashtags to make your posts easier to discover.
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            2. Discover Content
          </Typography>
          <Typography variant="body2" paragraph>
            Browse through the memory grid to see what others have shared.
            Click on hashtags to filter content by topics you're interested in.
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            3. Weekly Limit
          </Typography>
          <Typography variant="body2" paragraph>
            To ensure quality content, there's a limit of 35 posts per user per week.
            The counter at the top of the upload bar shows how many posts you have left.
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            4. Fun Features
          </Typography>
          <Typography variant="body2" paragraph>
            Try the slideshow mode to view random memories in a beautiful full-screen display.
            Toggle between dark and light mode using the button in the header.
          </Typography>
          
          <Typography variant="body1" paragraph sx={{ mt: 2, fontStyle: 'italic' }}>
            All content must be related to the Rabbit R1 device. Other content will be automatically filtered.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWelcome} color="primary" variant="contained">
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Layout;
