import React, { useState, useEffect } from 'react';
import { 
  Container, Typography, Box, Button, Dialog, DialogTitle, 
  DialogContent, DialogContentText, DialogActions, Snackbar, 
  Alert, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Paper, CircularProgress, Card, CardContent, IconButton,
  Tabs, Tab, TextField, Divider, Chip, Link
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { 
  deleteAllMemories, getMemories, deleteMemory, getAnnouncements, 
  createAnnouncement, getArticles, deleteArticle, approveArticle, 
  deleteAllArticles
} from '../api/supabase';
import { Memory, Announcement, Article } from '../api/supabase';

const AdminPage: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [loading, setLoading] = useState(false);
  const [memories, setMemories] = useState<Memory[]>([]);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [loadingMemories, setLoadingMemories] = useState(true);
  const [loadingAnnouncements, setLoadingAnnouncements] = useState(true);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [memoryToDelete, setMemoryToDelete] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [announcementForm, setAnnouncementForm] = useState({
    title: '',
    content: ''
  });
  const [tabValue, setTabValue] = useState(0);

  // Load memories on component mount
  useEffect(() => {
    fetchMemories();
    fetchAnnouncements();
    fetchArticles();
  }, []);

  const fetchMemories = async () => {
    setLoadingMemories(true);
    try {
      const data = await getMemories();
      setMemories(data);
    } catch (error) {
      console.error('Error fetching memories:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to load memories. Check console for details.');
      setSnackbarOpen(true);
    } finally {
      setLoadingMemories(false);
    }
  };

  const fetchAnnouncements = async () => {
    setLoadingAnnouncements(true);
    try {
      const data = await getAnnouncements();
      setAnnouncements(data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to load announcements. Check console for details.');
      setSnackbarOpen(true);
    } finally {
      setLoadingAnnouncements(false);
    }
  };

  const fetchArticles = async () => {
    setLoadingArticles(true);
    try {
      const data = await getArticles(false); // Get all articles including unapproved
      setArticles(data);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to load articles. Check console for details.');
      setSnackbarOpen(true);
    } finally {
      setLoadingArticles(false);
    }
  };

  const handleClearAllMemories = async () => {
    setDialogOpen(false);
    setLoading(true);
    
    try {
      await deleteAllMemories();
      setSnackbarSeverity('success');
      setSnackbarMessage('All memories have been cleared successfully');
      setSnackbarOpen(true);
      // Refresh the memories list
      setMemories([]);
      fetchMemories();
    } catch (error: any) {
      console.error('Error clearing memories:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(`Failed to clear memories: ${error?.message || 'Unknown error'}`);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSingleMemory = async () => {
    if (!memoryToDelete) return;
    
    setConfirmDialogOpen(false);
    setLoading(true);
    
    try {
      await deleteMemory(memoryToDelete);
      setSnackbarSeverity('success');
      setSnackbarMessage('Memory deleted successfully');
      setSnackbarOpen(true);
      
      // Update the memories list
      setMemories(memories.filter(memory => memory.id !== memoryToDelete));
    } catch (error: any) {
      console.error('Error deleting memory:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(`Failed to delete memory: ${error?.message || 'Unknown error'}`);
      setSnackbarOpen(true);
      // Refresh the memories list to ensure we have the latest data
      fetchMemories();
    } finally {
      setLoading(false);
      setMemoryToDelete(null);
    }
  };

  const openDeleteConfirmDialog = (id: string) => {
    setMemoryToDelete(id);
    setConfirmDialogOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAnnouncementFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setAnnouncementForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreateAnnouncement = async () => {
    if (!announcementForm.title.trim() || !announcementForm.content.trim()) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Title and content are required');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      await createAnnouncement({
        title: announcementForm.title,
        content: announcementForm.content
      });
      setSnackbarSeverity('success');
      setSnackbarMessage('Announcement created successfully');
      setSnackbarOpen(true);
      // Reset form
      setAnnouncementForm({
        title: '',
        content: ''
      });
      // Refresh announcements
      fetchAnnouncements();
    } catch (error: any) {
      console.error('Error creating announcement:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(`Failed to create announcement: ${error?.message || 'Unknown error'}`);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteArticle = async (id: string) => {
    setLoading(true);
    try {
      await deleteArticle(id);
      setSnackbarSeverity('success');
      setSnackbarMessage('Article deleted successfully');
      setSnackbarOpen(true);
      // Refresh articles list
      fetchArticles();
    } catch (error: any) {
      console.error('Error deleting article:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(`Failed to delete article: ${error?.message || 'Unknown error'}`);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleApproveArticle = async (id: string) => {
    setLoading(true);
    try {
      await approveArticle(id);
      setSnackbarSeverity('success');
      setSnackbarMessage('Article approved successfully');
      setSnackbarOpen(true);
      // Refresh articles list
      fetchArticles();
    } catch (error: any) {
      console.error('Error approving article:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(`Failed to approve article: ${error?.message || 'Unknown error'}`);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClearAllArticles = async () => {
    setLoading(true);
    try {
      await deleteAllArticles();
      setSnackbarSeverity('success');
      setSnackbarMessage('All articles have been cleared successfully');
      setSnackbarOpen(true);
      // Refresh articles list
      setArticles([]);
      fetchArticles();
    } catch (error: any) {
      console.error('Error clearing articles:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(`Failed to clear articles: ${error?.message || 'Unknown error'}`);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Format date to a readable string
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Admin Dashboard
        </Typography>
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="admin tabs">
            <Tab label="Memories" />
            <Tab label="Announcements" />
            <Tab label="Articles" />
          </Tabs>
        </Box>

        {tabValue === 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Memory Management
            </Typography>
            
            <Box sx={{ mt: 2, mb: 4 }}>
              <Button 
                variant="contained" 
                color="error" 
                onClick={() => setDialogOpen(true)}
                disabled={loading}
                sx={{ mr: 2 }}
              >
                Clear All Memories
              </Button>
              
              <Button 
                variant="outlined" 
                onClick={fetchMemories}
                disabled={loading}
              >
                Refresh List
              </Button>
            </Box>
            
            <Typography variant="h6" gutterBottom>
              Memory List ({memories.length})
            </Typography>
            
            {loadingMemories ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            ) : memories.length === 0 ? (
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="body1" color="text.secondary" align="center">
                    No memories found
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Content</TableCell>
                      <TableCell>Likes</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {memories.map(memory => (
                      <TableRow key={memory.id}>
                        <TableCell>{formatDate(memory.created_at)}</TableCell>
                        <TableCell sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {memory.content?.substring(0, 100) || 'No content'}
                        </TableCell>
                        <TableCell>{memory.likes}</TableCell>
                        <TableCell>
                          <IconButton 
                            color="error" 
                            onClick={() => openDeleteConfirmDialog(memory.id)}
                            disabled={loading}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Create New Announcement
            </Typography>
            
            <Card sx={{ mb: 4, p: 2 }}>
              <CardContent>
                <TextField
                  fullWidth
                  label="Announcement Title"
                  name="title"
                  value={announcementForm.title}
                  onChange={handleAnnouncementFormChange}
                  margin="normal"
                  variant="outlined"
                />
                
                <TextField
                  fullWidth
                  label="Announcement Content"
                  name="content"
                  value={announcementForm.content}
                  onChange={handleAnnouncementFormChange}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={4}
                />
                
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button 
                    variant="contained" 
                    onClick={handleCreateAnnouncement}
                    disabled={loading}
                  >
                    Post Announcement
                  </Button>
                </Box>
              </CardContent>
            </Card>
            
            <Divider sx={{ my: 3 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                Announcement List ({announcements.length})
              </Typography>
              
              <Button 
                variant="outlined" 
                onClick={fetchAnnouncements}
                disabled={loading}
                size="small"
              >
                Refresh List
              </Button>
            </Box>
            
            {loadingAnnouncements ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            ) : announcements.length === 0 ? (
              <Card>
                <CardContent>
                  <Typography variant="body1" color="text.secondary" align="center">
                    No announcements found
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <Box>
                {announcements.map(announcement => (
                  <Card key={announcement.id} sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {announcement.title}
                      </Typography>
                      
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Posted: {formatDate(announcement.created_at)}
                      </Typography>
                      
                      <Typography variant="body1">
                        {announcement.content}
                      </Typography>
                      
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
                        Read by: {announcement.read_by?.length || 0} users
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}
          </Box>
        )}

        {tabValue === 2 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Article Management
            </Typography>
            
            <Box sx={{ mt: 2, mb: 4 }}>
              <Button 
                variant="contained" 
                color="error" 
                onClick={handleClearAllArticles}
                disabled={loading}
                sx={{ mr: 2 }}
              >
                Clear All Articles
              </Button>
              
              <Button 
                variant="outlined" 
                onClick={fetchArticles}
                disabled={loading}
              >
                Refresh List
              </Button>
            </Box>
            
            <Typography variant="h6" gutterBottom>
              Article List ({articles.length})
            </Typography>
            
            {loadingArticles ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            ) : articles.length === 0 ? (
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="body1" color="text.secondary" align="center">
                    No articles found
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>URL</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {articles.map(article => (
                      <TableRow key={article.id}>
                        <TableCell>{article.title}</TableCell>
                        <TableCell sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <Link href={article.url} target="_blank" rel="noopener noreferrer">
                            {article.url}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {article.approved ? (
                            <Chip label="Approved" color="success" />
                          ) : (
                            <Chip label="Pending" color="warning" />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton 
                            color="error" 
                            onClick={() => handleDeleteArticle(article.id)}
                            disabled={loading}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {!article.approved && (
                            <IconButton 
                              color="success" 
                              onClick={() => handleApproveArticle(article.id)}
                              disabled={loading}
                            >
                              <CheckCircleIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
      </Box>
      
      {/* Clear All Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Clear All Memories?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete all memories? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleClearAllMemories} color="error" variant="contained">
            Yes, Delete All
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Single Delete Confirmation Dialog */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Delete Memory?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this memory? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteSingleMemory} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminPage;
