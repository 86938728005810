import axios from 'axios';
import { getYoutubeTranscript } from './youtubeTranscript';

interface ModerationResponse {
  id: string;
  model: string;
  results: {
    flagged: boolean;
    categories: Record<string, boolean>;
    category_scores: Record<string, number>;
  }[];
}

interface ContentCheckResult {
  isApproved: boolean;
  reason?: string;
}

export const checkContentForR1Relevance = async (content: string, url?: string): Promise<ContentCheckResult> => {
  try {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    
    if (!apiKey) {
      console.error('OpenAI API key is missing');
      return { isApproved: false, reason: 'Moderation service unavailable' };
    }
    
    // First check: See if content contains references to Rabbit R1
    const lowerContent = content.toLowerCase();
    const hasR1Reference = lowerContent.includes('rabbit r1') || 
                           lowerContent.includes('r1 device') || 
                           lowerContent.includes('rabbit device') ||
                           lowerContent.includes('rabbit ai') ||
                           lowerContent.includes('rabbit inc');
    
    // If it's a YouTube URL, get and check the transcript
    let transcriptRelevant = false;
    if (url && url.includes('youtube.com') || url?.includes('youtu.be')) {
      try {
        const transcript = await getYoutubeTranscript(url);
        const lowerTranscript = transcript.toLowerCase();
        transcriptRelevant = lowerTranscript.includes('rabbit r1') || 
                             lowerTranscript.includes('r1 device') || 
                             lowerTranscript.includes('rabbit device') ||
                             lowerTranscript.includes('rabbit ai') ||
                             lowerTranscript.includes('rabbit inc');
      } catch (error) {
        console.error('Error fetching YouTube transcript:', error);
        // If we can't get the transcript, we'll just rely on other checks
      }
    }
    
    // If we already found R1 references, no need to use the OpenAI API
    if (hasR1Reference || transcriptRelevant) {
      return { isApproved: true };
    }
    
    // Use OpenAI's moderation API with a custom prompt to check R1 relevance
    const contextPrompt = 
      "Determine if this content is relevant to the Rabbit R1 device from Rabbit Inc. " +
      "The Rabbit R1 is an AI-powered portable device. " +
      "Content to check: " + content;
    
    const response = await axios.post<ModerationResponse>(
      'https://api.openai.com/v1/moderations',
      { input: contextPrompt },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        }
      }
    );
    
    // We'll also check for harmful content while we're at it
    const isFlagged = response.data.results[0].flagged;
    
    if (isFlagged) {
      return { 
        isApproved: false, 
        reason: 'Content contains inappropriate material' 
      };
    }
    
    // If we've made it here, the content wasn't flagged as inappropriate,
    // but we still need to determine R1 relevance using GPT
    const moderationResponse = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are a content moderator for a site dedicated to the Rabbit R1 device from Rabbit Inc. Your job is to determine if content is relevant to the Rabbit R1.'
          },
          {
            role: 'user',
            content: `Is this content relevant to the Rabbit R1 device? Respond with only "yes" or "no".\n\nContent: ${content}`
          }
        ],
        max_tokens: 10,
        temperature: 0
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        }
      }
    );
    
    const gptResponse = moderationResponse.data.choices[0].message.content.toLowerCase();
    const isRelevant = gptResponse.includes('yes');
    
    return {
      isApproved: isRelevant,
      reason: isRelevant ? undefined : 'Content is not related to Rabbit R1'
    };
    
  } catch (error) {
    console.error('Moderation error:', error);
    return { 
      isApproved: false, 
      reason: 'Error checking content relevance' 
    };
  }
};
