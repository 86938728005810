import axios from 'axios';

// Function to get the user's IP address
export const getUserIp = async (): Promise<string> => {
  try {
    // We'll use a public API to get the user's IP
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    // Fallback to a random identifier if we can't get the IP
    // This is not ideal but better than nothing for tracking weekly posts
    return `anon-${Math.random().toString(36).substring(2, 15)}`;
  }
};

// Function to create a consistent fingerprint for the user
// This helps track weekly posts even if IP changes
export const createUserFingerprint = async (): Promise<string> => {
  const ip = await getUserIp();
  
  // We'll also use some browser information to create a more stable identifier
  const browserInfo = {
    userAgent: navigator.userAgent,
    language: navigator.language,
    platform: navigator.platform,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
  };
  
  // Create a simple hash from the IP and browser info
  // In a real app you might want to use a more sophisticated fingerprinting library
  const fingerprintData = ip + JSON.stringify(browserInfo);
  let hash = 0;
  for (let i = 0; i < fingerprintData.length; i++) {
    const char = fingerprintData.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  
  return `${ip}-${Math.abs(hash).toString(36)}`;
};
