import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  Alert, 
  CircularProgress, 
  Collapse,
  Divider
} from '@mui/material';
import { submitArticle } from '../../api/supabase';
import { extractArticleMetadata } from '../../api/urlEmbed';
import { isContentAppropriate } from '../../api/openai';
import { checkContentForR1Relevance } from '../../api/moderation';
import { createUserFingerprint } from '../../utils/ipAddress';

const ArticleSubmission: React.FC = () => {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateUrl = (url: string): boolean => {
    try {
      const urlObj = new URL(url);
      return urlObj.protocol === 'http:' || urlObj.protocol === 'https:';
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset states
    setError(null);
    setSuccess(null);
    setIsLoading(true);
    setIsSubmitting(true);
    
    try {
      // Step 1: Validate URL format
      if (!validateUrl(url)) {
        throw new Error('Please enter a valid URL starting with http:// or https://');
      }
      
      // Step 2: Extract metadata from the URL
      const metadata = await extractArticleMetadata(url);
      
      // Step 3: Check if the content is related to Rabbit R1
      const contentToCheck = `${metadata.title}\n${metadata.description}`;
      const r1Check = await checkContentForR1Relevance(contentToCheck, url);
      
      if (!r1Check.isApproved) {
        throw new Error(`This article doesn't appear to be related to Rabbit R1. ${r1Check.reason || ''}`);
      }
      
      // Step 4: Check the content against OpenAI's moderation API for appropriateness
      const moderationCheck = await isContentAppropriate(contentToCheck);
      
      if (!moderationCheck.appropriate) {
        throw new Error('The content was flagged by our moderation system and cannot be submitted');
      }
      
      // Step 5: Get user's fingerprint/IP
      const userFingerprint = await createUserFingerprint();
      
      // Step 6: Submit article to database - automatically approved if it passed R1 relevance check
      await submitArticle({
        title: metadata.title,
        url: url,
        source: metadata.source,
        description: metadata.description,
        image_url: metadata.image_url,
        submitted_by: userFingerprint,
        moderation_result: {
          appropriate: moderationCheck.appropriate,
          moderation_result: moderationCheck.moderationResult,
          message: moderationCheck.message,
          r1_relevant: r1Check.isApproved
        }
      });
      
      // Success! Clear the form
      setUrl('');
      setSuccess('Your article about Rabbit R1 has been automatically approved and published!');
    } catch (error: any) {
      console.error('Error submitting article:', error);
      setError(error.message || 'Failed to submit article. Please try again later.');
    } finally {
      setIsLoading(false);
      
      // Reset submission after a few seconds to allow user to submit again
      setTimeout(() => {
        setIsSubmitting(false);
      }, 3000);
    }
  };

  return (
    <Paper 
      elevation={2} 
      sx={{ 
        p: 3, 
        mb: 4, 
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: 4
        }
      }}
    >
      <Typography 
        variant="h6" 
        component="h2" 
        gutterBottom 
        sx={{ mb: 2, fontWeight: 'bold' }}
      >
        Share a Rabbit R1 Article or Link
      </Typography>
      
      <Divider sx={{ mb: 3 }} />
      
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <TextField
            fullWidth
            label="Article URL"
            variant="outlined"
            size="medium"
            placeholder="https://example.com/article-about-rabbit-r1"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            disabled={isLoading}
            sx={{ mr: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading || !url.trim() || isSubmitting}
            sx={{ whiteSpace: 'nowrap', minWidth: '120px' }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </Box>
        
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Share links to interesting articles, news, or content related to Rabbit R1.
          All submissions are moderated before appearing on the site.
        </Typography>
        
        <Collapse in={!!error || !!success}>
          <Box sx={{ mt: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

export default ArticleSubmission;
