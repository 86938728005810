import React from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  Box, 
  Link, 
  Chip,
  CardActionArea
} from '@mui/material';
import { Article } from '../../api/supabase';
import { formatDistance } from 'date-fns';

interface ArticleCardProps {
  article: Article;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  const formattedDate = formatDistance(
    new Date(article.created_at),
    new Date(),
    { addSuffix: true }
  );

  const handleClick = () => {
    window.open(article.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card 
      elevation={2} 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)'
        }
      }}
    >
      <CardActionArea onClick={handleClick} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        {article.image_url && (
          <CardMedia
            component="img"
            height="140"
            image={article.image_url}
            alt={article.title}
            sx={{ objectFit: 'cover' }}
          />
        )}
        
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Chip 
              label={article.source} 
              size="small" 
              color="primary" 
              variant="outlined" 
              sx={{ height: 20, fontSize: '0.7rem' }}
            />
            <Typography variant="caption" color="text.secondary">
              {formattedDate}
            </Typography>
          </Box>
          
          <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
            {article.title}
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5, flexGrow: 1 }}>
            {article.description.length > 120 
              ? `${article.description.substring(0, 120)}...` 
              : article.description}
          </Typography>
          
          <Link 
            color="primary" 
            sx={{ 
              mt: 'auto', 
              fontWeight: 'medium', 
              fontSize: '0.9rem',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={(e) => e.stopPropagation()}
            href={article.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Article
          </Link>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ArticleCard;
