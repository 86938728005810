import axios from 'axios';
import { UrlEmbedData } from './supabase';

// Regex patterns for different media types
const imageRegex = /\.(jpeg|jpg|gif|png|webp)$/i;
const videoRegex = /\.(mp4|webm|ogg|mov)$/i;
const audioRegex = /\.(mp3|wav|ogg|m4a)$/i;
const youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;

// Platform-specific regex patterns
const discordAttachmentRegex = /https:\/\/(?:cdn|media)\.discordapp\.(?:com|net)\/attachments\/(\d+)\/(\d+)\/([^?]+)/i;
const discordImageRegex = /https:\/\/(?:cdn|media)\.discordapp\.(?:com|net)\/attachments\/\d+\/\d+\/[^?]+\.(jpeg|jpg|gif|png|webp)/i;
const discordVideoRegex = /https:\/\/(?:cdn|media)\.discordapp\.(?:com|net)\/attachments\/\d+\/\d+\/[^?]+\.(mp4|webm|mov)/i;
const twitterRegex = /https?:\/\/(?:www\.|mobile\.)?(?:twitter\.com|x\.com)\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)/i;
const redditPostRegex = /https?:\/\/(?:www\.|old\.|new\.)?reddit\.com\/r\/([^\/]+)\/comments\/([^\/]+)/i;
const redditGalleryRegex = /https?:\/\/(?:www\.|old\.|new\.)?reddit\.com\/gallery\/([a-zA-Z0-9]+)/i;
const instagramRegex = /https?:\/\/(?:www\.)?instagram\.com\/(?:p|reel)\/([^\/\?#]+)/i;
const r1MemoriesRegex = /https?:\/\/(?:www\.)?r1memories\.com\/?([^?#]*)?/i;

// Helper function to handle Discord CDN URLs
const getProxiedDiscordUrl = (url: string): string => {
  // Discord URLs sometimes require authentication, so we'll use a proxy service
  // to make them publicly accessible. 
  // This is just a simple example - in production you might want to use a more robust solution.
  try {
    // Check if it's already using a proxy
    if (url.includes('proxy.approach.be') || url.includes('media.discordapp.net')) {
      return url;
    }
    
    // Replace cdn.discordapp.com with media.discordapp.net for better public access
    if (url.includes('cdn.discordapp.com')) {
      return url.replace('cdn.discordapp.com', 'media.discordapp.net');
    }
    
    return url;
  } catch (error) {
    console.error('Error processing Discord URL:', error);
    return url;
  }
};

// Function to fetch metadata from a URL
export const getUrlMetadata = async (url: string): Promise<UrlEmbedData> => {
  try {
    // Check if it's a direct media file
    if (imageRegex.test(url)) {
      return {
        title: 'Image',
        mediaType: 'image',
        mediaUrl: url,
      };
    }
    
    if (videoRegex.test(url)) {
      return {
        title: 'Video',
        mediaType: 'video',
        mediaUrl: url,
      };
    }
    
    if (audioRegex.test(url)) {
      return {
        title: 'Audio',
        mediaType: 'audio',
        mediaUrl: url,
      };
    }
    
    // Check if it's a YouTube URL
    const youtubeMatch = url.match(youtubeRegex);
    if (youtubeMatch && youtubeMatch[1]) {
      const videoId = youtubeMatch[1];
      return {
        title: 'YouTube Video',
        mediaType: 'video',
        mediaUrl: `https://www.youtube.com/embed/${videoId}`,
        image: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
      };
    }
    
    // Check if it's a Discord image or video URL
    const discordImageMatch = url.match(discordImageRegex);
    if (discordImageMatch) {
      const proxiedUrl = getProxiedDiscordUrl(url);
      return {
        title: 'Discord Image',
        mediaType: 'image',
        mediaUrl: proxiedUrl,
        source: 'discord'
      };
    }
    
    const discordVideoMatch = url.match(discordVideoRegex);
    if (discordVideoMatch) {
      const proxiedUrl = getProxiedDiscordUrl(url);
      return {
        title: 'Discord Video',
        mediaType: 'video',
        mediaUrl: proxiedUrl,
        source: 'discord'
      };
    }
    
    const discordAttachmentMatch = url.match(discordAttachmentRegex);
    if (discordAttachmentMatch) {
      const proxiedUrl = getProxiedDiscordUrl(url);
      const filename = discordAttachmentMatch[3].toLowerCase();
      
      // Determine the type of attachment based on file extension
      if (imageRegex.test(filename)) {
        return {
          title: 'Discord Image',
          mediaType: 'image',
          mediaUrl: proxiedUrl,
          source: 'discord'
        };
      } else if (videoRegex.test(filename)) {
        return {
          title: 'Discord Video',
          mediaType: 'video',
          mediaUrl: proxiedUrl,
          source: 'discord'
        };
      } else if (audioRegex.test(filename)) {
        return {
          title: 'Discord Audio',
          mediaType: 'audio',
          mediaUrl: proxiedUrl,
          source: 'discord'
        };
      } else {
        return {
          title: 'Discord Attachment',
          mediaType: 'link',
          mediaUrl: proxiedUrl,
          source: 'discord'
        };
      }
    }
    
    // Check if it's a Twitter URL
    const twitterMatch = url.match(twitterRegex);
    if (twitterMatch) {
      const username = twitterMatch[1];
      const tweetId = twitterMatch[2];
      
      return {
        title: 'Twitter Post',
        description: `Tweet from @${username}`,
        mediaType: 'link',
        mediaUrl: url,
        source: 'twitter',
        author: username
      };
    }
    
    // Check if it's a Reddit post or gallery URL
    const redditPostMatch = url.match(redditPostRegex);
    if (redditPostMatch) {
      const subreddit = redditPostMatch[1];
      
      return {
        title: `r/${subreddit} Post`,
        description: `Post from r/${subreddit} subreddit`,
        mediaType: 'link',
        mediaUrl: url,
        source: 'reddit',
        author: `r/${subreddit}`
      };
    }
    
    const redditGalleryMatch = url.match(redditGalleryRegex);
    if (redditGalleryMatch) {
      return {
        title: 'Reddit Gallery',
        description: 'Photo gallery from Reddit',
        mediaType: 'link',
        mediaUrl: url,
        source: 'reddit'
      };
    }
    
    // Check if it's an Instagram URL
    const instagramMatch = url.match(instagramRegex);
    if (instagramMatch) {
      const postId = instagramMatch[1];
      
      return {
        title: 'Instagram Post',
        description: 'Photo or video from Instagram',
        mediaType: 'link',
        mediaUrl: url,
        source: 'instagram'
      };
    }
    
    // Check if it's r1memories.com URL (special case for our own site)
    const r1MemoriesMatch = url.match(r1MemoriesRegex);
    if (r1MemoriesMatch) {
      return {
        title: 'R1Memories',
        description: 'A social platform for sharing Rabbit R1 experiences, tips, and memories',
        mediaType: 'link',
        mediaUrl: url,
        image: 'https://r1memories.com/og-image.png',
        favicon: 'https://r1memories.com/favicon.ico'
      };
    }
    
    // For other URLs, we need to make a request to get metadata
    // We'll use a proxy server to avoid CORS issues
    // In a production environment, you would have a backend service for this
    const response = await axios.get(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`);
    
    if (response.status !== 200) {
      throw new Error('Failed to fetch URL data');
    }
    
    const html = response.data.contents;
    
    // Simple parsing of metadata from HTML
    const title = html.match(/<title[^>]*>([^<]+)<\/title>/i)?.[1] || '';
    const description = html.match(/<meta[^>]*name=["']description["'][^>]*content=["']([^"']+)["'][^>]*>/i)?.[1] || 
                        html.match(/<meta[^>]*property=["']og:description["'][^>]*content=["']([^"']+)["'][^>]*>/i)?.[1] || '';
    const image = html.match(/<meta[^>]*property=["']og:image["'][^>]*content=["']([^"']+)["'][^>]*>/i)?.[1] || '';
    const faviconRelative = html.match(/<link[^>]*rel=["'](?:shortcut )?icon["'][^>]*href=["']([^"']+)["'][^>]*>/i)?.[1] || '';
    
    // Construct absolute URL for favicon
    let favicon = '';
    if (faviconRelative) {
      if (faviconRelative.startsWith('http')) {
        favicon = faviconRelative;
      } else if (faviconRelative.startsWith('//')) {
        favicon = 'https:' + faviconRelative;
      } else {
        const urlObj = new URL(url);
        favicon = `${urlObj.protocol}//${urlObj.host}${faviconRelative.startsWith('/') ? '' : '/'}${faviconRelative}`;
      }
    }
    
    return {
      title,
      description,
      image,
      favicon,
      mediaType: 'link'
    };
    
  } catch (error) {
    console.error('Error fetching URL metadata:', error);
    return {
      title: url,
      description: 'Could not fetch metadata for this URL',
      mediaType: 'link'
    };
  }
};

export const extractArticleMetadata = async (url: string): Promise<{
  title: string;
  description: string;
  image_url?: string;
  source: string;
}> => {
  try {
    // Try to fetch OG data for the URL
    const metadata = await getUrlMetadata(url);
    
    // Extract the domain as the source
    let source = '';
    try {
      const urlObj = new URL(url);
      source = urlObj.hostname.replace('www.', '');
    } catch (e) {
      console.error('Error parsing URL:', e);
      source = 'Unknown Source';
    }
    
    return {
      title: metadata.title || 'Unknown Title',
      description: metadata.description || 'No description available',
      image_url: metadata.image,
      source
    };
  } catch (error) {
    console.error('Error extracting article metadata:', error);
    
    // Extract domain as fallback source
    let source = 'Unknown Source';
    try {
      const urlObj = new URL(url);
      source = urlObj.hostname.replace('www.', '');
    } catch (e) {
      console.error('Error parsing URL:', e);
    }
    
    // Return basic metadata if we can't fetch it
    return {
      title: 'Article about Rabbit R1',
      description: 'Details not available. Click to read the full article.',
      source
    };
  }
};

// Note: isRabbitR1Related function has been removed and replaced by the more comprehensive
// checkContentForR1Relevance function in moderation.ts
