import React, { useState, useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import UploadBar from '../components/UploadBar/UploadBar';
import MemoryGrid from '../components/Memory/MemoryGrid';
import { supabase } from '../api/supabase';

const HomePage: React.FC = () => {
  const [isSupabaseConnected, setIsSupabaseConnected] = useState<boolean>(false);
  const [connectionError, setConnectionError] = useState<string | null>(null);
  
  // Check Supabase connection on component mount
  useEffect(() => {
    const checkConnection = async () => {
      try {
        const { data, error } = await supabase.from('memories').select('count').limit(1);
        if (error) throw error;
        setIsSupabaseConnected(true);
      } catch (error) {
        console.error('Supabase connection error:', error);
        setConnectionError('Could not connect to the database. Please check your API keys in the .env file.');
      }
    };
    
    checkConnection();
  }, []);
  
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom 
          align="center"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            mb: 2
          }}
        >
          r1memories
        </Typography>
        <Typography 
          variant="h6" 
          align="center" 
          color="text.secondary"
          sx={{ mb: 6 }}
        >
          Share and discover memories about the Rabbit R1
        </Typography>
        
        {connectionError ? (
          <Box sx={{ p: 3, bgcolor: 'error.main', color: 'white', borderRadius: 2, mb: 4 }}>
            <Typography variant="body1">{connectionError}</Typography>
          </Box>
        ) : null}
        
        <UploadBar />
        <MemoryGrid />
      </Box>
    </Container>
  );
};

export default HomePage;
