import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  Box, 
  Chip, 
  IconButton, 
  CardActions, 
  Tooltip,
  Link,
  Paper
} from '@mui/material';
import { 
  ThumbUp as ThumbUpIcon, 
  OpenInNew as OpenInNewIcon,
  Fullscreen as FullscreenIcon,
  Person as PersonIcon,
  Twitter as TwitterIcon,
  Reddit as RedditIcon,
  Instagram as InstagramIcon
} from '@mui/icons-material';
import { Memory, updateMemoryLikes } from '../../api/supabase';
import { createUserFingerprint } from '../../utils/ipAddress';

interface MemoryCardProps {
  memory: Memory;
  onHashtagClick: (hashtag: string) => void;
  onOpenInSlideshow: (memory: Memory) => void;
}

const MemoryCard: React.FC<MemoryCardProps> = ({ memory, onHashtagClick, onOpenInSlideshow }) => {
  // Get a unique identifier for this user/browser
  const [userId, setUserId] = useState<string | null>(null);
  
  // Generate a user ID on component mount
  useEffect(() => {
    const generateUserId = async () => {
      try {
        const fingerprint = await createUserFingerprint();
        setUserId(fingerprint);
        
        // Check if this user has liked the post
        const likedPosts = JSON.parse(localStorage.getItem(`likedPosts_${fingerprint}`) || '{}');
        setIsLiked(likedPosts[memory.id] === true);
      } catch (error) {
        console.error('Error generating user ID:', error);
      }
    };
    
    generateUserId();
  }, []); // Only run once on mount
  
  // Initialize state
  const [likes, setLikes] = useState(memory.likes || 0);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  
  // Update likes state when memory prop changes
  useEffect(() => {
    setLikes(memory.likes || 0);
  }, [memory.likes]);
  
  // Check if this memory is liked whenever memory or userId changes
  useEffect(() => {
    if (userId) {
      const likedPosts = JSON.parse(localStorage.getItem(`likedPosts_${userId}`) || '{}');
      setIsLiked(likedPosts[memory.id] === true);
    }
  }, [userId, memory.id]);
  
  const handleLike = async () => {
    if (isDisabled || !userId) return;
    
    setIsDisabled(true);
    
    try {
      if (isLiked) {
        // If already liked, unlike
        const newLikes = Math.max(0, likes - 1); // Ensure it doesn't go below 0
        setLikes(newLikes);
        setIsLiked(false);
        
        // Update localStorage first
        const likedPosts = JSON.parse(localStorage.getItem(`likedPosts_${userId}`) || '{}');
        delete likedPosts[memory.id];
        localStorage.setItem(`likedPosts_${userId}`, JSON.stringify(likedPosts));
        
        // Then update the database
        const updatedMemory = await updateMemoryLikes(memory.id, newLikes);
        console.log('Memory unliked, new data:', updatedMemory);
        
        // Update UI with value from database to ensure accuracy
        if (updatedMemory) {
          setLikes(updatedMemory.likes || 0);
        }
      } else {
        // Like the post
        const newLikes = likes + 1;
        setLikes(newLikes);
        setIsLiked(true);
        
        // Update localStorage first
        const likedPosts = JSON.parse(localStorage.getItem(`likedPosts_${userId}`) || '{}');
        likedPosts[memory.id] = true;
        localStorage.setItem(`likedPosts_${userId}`, JSON.stringify(likedPosts));
        
        // Then update the database
        const updatedMemory = await updateMemoryLikes(memory.id, newLikes);
        console.log('Memory liked, new data:', updatedMemory);
        
        // Update UI with value from database to ensure accuracy
        if (updatedMemory) {
          setLikes(updatedMemory.likes || 0);
        }
      }
    } catch (error) {
      console.error('Error updating likes:', error);
      // Revert UI changes on error
      setIsLiked(!isLiked);
      setLikes(memory.likes || 0);
      
      // Revert localStorage on error
      if (userId) {
        const likedPosts = JSON.parse(localStorage.getItem(`likedPosts_${userId}`) || '{}');
        if (isLiked) {
          delete likedPosts[memory.id];
        } else {
          likedPosts[memory.id] = true;
        }
        localStorage.setItem(`likedPosts_${userId}`, JSON.stringify(likedPosts));
      }
    } finally {
      setIsDisabled(false);
    }
  };
  
  // Format date to a readable string
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };
  
  // Render different media types
  const renderMedia = () => {
    if (!memory.embed_data) return null;
    
    const { mediaType, mediaUrl, image, title } = memory.embed_data;
    
    switch (mediaType) {
      case 'image':
        return (
          <CardMedia
            component="img"
            height="200"
            image={mediaUrl || image}
            alt="Memory image"
            sx={{ objectFit: 'cover' }}
          />
        );
      case 'video':
        if (mediaUrl?.includes('youtube.com/embed/')) {
          return (
            <Box sx={{ position: 'relative', paddingTop: '56.25%' }}> {/* 16:9 aspect ratio */}
              <iframe
                src={`${mediaUrl}?autoplay=0`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded video"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0
                }}
              />
            </Box>
          );
        } else {
          return (
            <Box sx={{ width: '100%' }}>
              <video
                controls
                src={mediaUrl}
                style={{ width: '100%', maxHeight: '300px' }}
              />
            </Box>
          );
        }
      case 'audio':
        return (
          <Box sx={{ width: '100%', p: 2 }}>
            <audio
              controls
              src={mediaUrl}
              style={{ width: '100%' }}
            />
          </Box>
        );
      default:
        return image ? (
          <CardMedia
            component="img"
            height="140"
            image={image}
            alt="Memory preview"
            sx={{ objectFit: 'cover' }}
          />
        ) : null;
    }
  };
  
  // Render URL card preview for links
  const renderUrlPreview = () => {
    if (!memory.url || !memory.embed_data || memory.embed_data.mediaType !== 'link') return null;
    
    const { title, description, favicon, mediaUrl, source, author, publishedAt } = memory.embed_data;
    
    // Check if it's a social media post
    const isSocialMediaPost = () => {
      return source === 'twitter' || source === 'reddit' || source === 'instagram';
    };
    
    // Determine which social media icon to show
    const getSocialMediaIcon = () => {
      if (!source) {
        // Fallback to URL detection
        if (memory.url) {
          const url = memory.url.toLowerCase();
          if (url.includes('twitter.com') || url.includes('x.com')) {
            return <TwitterIcon fontSize="small" color="primary" />;
          } else if (url.includes('reddit.com')) {
            return <RedditIcon fontSize="small" color="error" />;
          } else if (url.includes('instagram.com')) {
            return <InstagramIcon fontSize="small" color="secondary" />;
          }
        }
        return null;
      }
      
      // Use the source property
      switch (source) {
        case 'twitter':
          return <TwitterIcon fontSize="small" color="primary" />;
        case 'reddit':
          return <RedditIcon fontSize="small" color="error" />;
        case 'instagram':
          return <InstagramIcon fontSize="small" color="secondary" />;
        default:
          return null;
      }
    };
    
    // Enhanced preview for social media posts
    if (isSocialMediaPost() || (memory.url && (memory.url.includes('twitter.com') || memory.url.includes('x.com') || 
             memory.url.includes('reddit.com') || memory.url.includes('instagram.com')))) {
      return (
        <Paper variant="outlined" sx={{ 
          m: 2, 
          p: 2, 
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            {getSocialMediaIcon()}
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {title}
            </Typography>
          </Box>
          
          {description && (
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {description}
            </Typography>
          )}
          
          {author && (
            <Typography variant="body2" color="primary.light" sx={{ mb: 1 }}>
              {author}
            </Typography>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link href={memory.url} target="_blank" rel="noopener noreferrer" variant="caption" color="primary">
              {(() => {
                try {
                  return new URL(memory.url).hostname;
                } catch (e) {
                  return memory.url;
                }
              })()}
            </Link>
            
            <IconButton 
              size="small" 
              href={memory.url} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Box>
        </Paper>
      );
    }
    
    // Default link preview
    return (
      <Box sx={{ 
        display: 'flex', 
        p: 2, 
        borderTop: '1px solid',
        borderColor: 'divider',
        alignItems: 'center',
      }}>
        {favicon && (
          <Box sx={{ mr: 2 }}>
            <img 
              src={favicon} 
              alt="Site favicon" 
              style={{ width: 24, height: 24, objectFit: 'contain' }} 
            />
          </Box>
        )}
        
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" component="div">
            {title || 'Link'}
          </Typography>
          
          {description && (
            <Typography variant="body2" color="text.secondary" noWrap>
              {description}
            </Typography>
          )}
          
          {author && (
            <Typography variant="body2" color="primary.light" sx={{ mb: 1 }}>
              {author}
            </Typography>
          )}
          
          {publishedAt && (
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Published at {formatDate(publishedAt)}
            </Typography>
          )}
          
          {memory.url && (
            <Link href={memory.url} target="_blank" rel="noopener noreferrer" variant="caption" color="primary">
              {(() => {
                try {
                  return new URL(memory.url).hostname;
                } catch (e) {
                  return memory.url;
                }
              })()}
            </Link>
          )}
        </Box>
        
        <IconButton 
          size="small" 
          href={memory.url} 
          target="_blank" 
          rel="noopener noreferrer"
          sx={{ ml: 1 }}
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };
  
  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: (theme) => `0 8px 16px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.1)'}`
        }
      }}
    >
      {renderMedia()}
      
      <CardContent sx={{ flexGrow: 1 }}>
        {memory.content && (
          <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
            {memory.content}
          </Typography>
        )}
        
        {memory.username && (
          <Typography variant="body2" color="primary.light" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
            <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
            {memory.username}
          </Typography>
        )}
        
        {memory.hashtags && Array.isArray(memory.hashtags) && memory.hashtags.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 2 }}>
            {memory.hashtags.map(tag => (
              <Chip
                key={tag}
                size="small"
                label={`#${tag}`}
                onClick={() => onHashtagClick(tag)}
                color="primary"
                variant="outlined"
                sx={{ cursor: 'pointer' }}
              />
            ))}
          </Box>
        )}
      </CardContent>
      
      {renderUrlPreview()}
      
      <CardActions sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        borderTop: '1px solid',
        borderColor: 'divider',
        px: 2
      }}>
        <Typography variant="caption" color="text.secondary">
          {formatDate(memory.created_at)}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Open in slideshow">
            <IconButton onClick={() => onOpenInSlideshow(memory)} size="small">
              <FullscreenIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            <IconButton 
              onClick={handleLike} 
              color={isLiked ? 'primary' : 'default'} 
              size="small"
            >
              <ThumbUpIcon fontSize="small" />
            </IconButton>
            <Typography variant="body2" sx={{ mx: 0.5 }}>
              {likes}
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default MemoryCard;
