import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Box, 
  Typography, 
  CircularProgress, 
  Paper, 
  Chip, 
  Button, 
  Dialog, 
  IconButton,
  useTheme,
  Slide
} from '@mui/material';
import { 
  Close as CloseIcon, 
  ChevronLeft as ChevronLeftIcon, 
  ChevronRight as ChevronRightIcon,
  FullscreenExit as FullscreenExitIcon,
  PlayArrow as PlayIcon,
  Pause as PauseIcon
} from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { Memory, getMemories, supabase } from '../../api/supabase';
import MemoryCard from './MemoryCard';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MemoryGrid: React.FC = () => {
  const [memories, setMemories] = useState<Memory[]>([]);
  const [filteredMemories, setFilteredMemories] = useState<Memory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeHashtags, setActiveHashtags] = useState<string[]>([]);
  
  // Slideshow state
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideshowOpen, setSlideshowOpen] = useState(false);
  const [slideshowPlaying, setSlideshowPlaying] = useState(false);
  const [slideshowTimer, setSlideshowTimer] = useState<NodeJS.Timeout | null>(null);
  
  const theme = useTheme();
  
  // Fetch memories
  useEffect(() => {
    const fetchMemories = async () => {
      try {
        setIsLoading(true);
        const data = await getMemories();
        setMemories(data);
        setFilteredMemories(data);
      } catch (error) {
        console.error('Error fetching memories:', error);
        setError('Failed to load memories. Please try refreshing the page.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchMemories();
    
    // Set up real-time subscription for new memories
    const subscription = supabase
      .channel('public:memories')
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'memories' 
      }, (payload) => {
        console.log('Real-time update received:', payload);
        fetchMemories();
      })
      .subscribe();
      
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  
  // Filter memories based on active hashtags
  useEffect(() => {
    if (activeHashtags.length === 0) {
      setFilteredMemories(memories);
    } else {
      const filtered = memories.filter(memory => 
        memory.hashtags && Array.isArray(memory.hashtags) && memory.hashtags.some(tag => activeHashtags.includes(tag))
      );
      setFilteredMemories(filtered);
    }
  }, [activeHashtags, memories]);
  
  // Handle slideshow timer
  useEffect(() => {
    // Clean up previous timer
    if (slideshowTimer) {
      clearInterval(slideshowTimer);
      setSlideshowTimer(null);
    }
    
    // Set new timer if slideshow is playing
    if (slideshowPlaying && slideshowOpen) {
      const timer = setInterval(() => {
        setSlideIndex(prevIndex => 
          prevIndex < filteredMemories.length - 1 ? prevIndex + 1 : 0
        );
      }, 5000); // Change slide every 5 seconds
      
      setSlideshowTimer(timer);
    }
    
    return () => {
      if (slideshowTimer) {
        clearInterval(slideshowTimer);
      }
    };
  }, [slideshowPlaying, slideshowOpen, filteredMemories.length]);
  
  const handleHashtagClick = (hashtag: string) => {
    if (activeHashtags.includes(hashtag)) {
      setActiveHashtags(activeHashtags.filter(tag => tag !== hashtag));
    } else {
      setActiveHashtags([...activeHashtags, hashtag]);
    }
  };
  
  const clearHashtagFilters = () => {
    setActiveHashtags([]);
  };
  
  const handleOpenSlideshow = (memory: Memory) => {
    const index = filteredMemories.findIndex(m => m.id === memory.id);
    if (index !== -1) {
      setSlideIndex(index);
      setSlideshowOpen(true);
      setSlideshowPlaying(false); // Start paused
    }
  };
  
  const handleCloseSlideshow = () => {
    setSlideshowOpen(false);
    setSlideshowPlaying(false);
  };
  
  const handlePrevSlide = () => {
    setSlideIndex(prevIndex => 
      prevIndex > 0 ? prevIndex - 1 : filteredMemories.length - 1
    );
  };
  
  const handleNextSlide = () => {
    setSlideIndex(prevIndex => 
      prevIndex < filteredMemories.length - 1 ? prevIndex + 1 : 0
    );
  };
  
  const toggleSlideshowPlayback = () => {
    setSlideshowPlaying(!slideshowPlaying);
  };
  
  const renderRandomSlideshowButton = () => {
    if (filteredMemories.length === 0) return null;
    
    return (
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PlayIcon />}
        onClick={() => {
          // Start with a random memory
          const randomIndex = Math.floor(Math.random() * filteredMemories.length);
          setSlideIndex(randomIndex);
          setSlideshowOpen(true);
          setSlideshowPlaying(true); // Start playing
        }}
        sx={{ mt: 2, mb: 3 }}
      >
        Play Random Slideshow
      </Button>
    );
  };
  
  const renderSlideshowControls = () => {
    return (
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          zIndex: 10,
        }}
      >
        <IconButton onClick={handlePrevSlide} color="inherit">
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
        
        <IconButton onClick={toggleSlideshowPlayback} color="inherit">
          {slideshowPlaying ? <PauseIcon fontSize="large" /> : <PlayIcon fontSize="large" />}
        </IconButton>
        
        <IconButton onClick={handleNextSlide} color="inherit">
          <ChevronRightIcon fontSize="large" />
        </IconButton>
        
        <Typography color="white" variant="caption" sx={{ position: 'absolute', right: 16, bottom: 16 }}>
          {slideIndex + 1} / {filteredMemories.length}
        </Typography>
      </Box>
    );
  };
  
  const renderSlideContent = () => {
    if (filteredMemories.length === 0 || slideIndex >= filteredMemories.length) {
      return null;
    }
    
    const currentMemory = filteredMemories[slideIndex];
    
    // For images
    if (currentMemory.embed_data?.mediaType === 'image' || 
        (currentMemory.embed_data?.mediaType === 'link' && currentMemory.embed_data?.image)) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src={currentMemory.embed_data.mediaUrl || currentMemory.embed_data.image}
            alt="Slideshow content"
            style={{ 
              maxWidth: '100%', 
              maxHeight: 'calc(100vh - 150px)', // Leave room for controls
              objectFit: 'contain',
              boxShadow: '0 8px 20px rgba(0,0,0,0.25)'
            }}
          />
          
          <Box sx={{ 
            mt: 3, 
            p: 2, 
            backgroundColor: 'rgba(0,0,0,0.7)', 
            borderRadius: 2, 
            maxWidth: '80%' 
          }}>
            {currentMemory.content && (
              <Typography variant="body1" color="white" align="center" sx={{ mb: 1 }}>
                {currentMemory.content}
              </Typography>
            )}
            
            {currentMemory.hashtags && Array.isArray(currentMemory.hashtags) && currentMemory.hashtags.length > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                {currentMemory.hashtags.map(tag => (
                  <Chip
                    key={tag}
                    size="small"
                    label={`#${tag}`}
                    color="primary"
                    sx={{ color: 'white' }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      );
    }
    
    // For videos
    if (currentMemory.embed_data?.mediaType === 'video') {
      return (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 150px)', // Leave room for controls
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {currentMemory.embed_data.mediaUrl?.includes('youtube.com/embed/') ? (
            <iframe
              width="80%"
              height="80%"
              src={`${currentMemory.embed_data.mediaUrl}?autoplay=1`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded video"
              style={{
                boxShadow: '0 8px 20px rgba(0,0,0,0.25)'
              }}
            />
          ) : (
            <video
              controls
              autoPlay
              src={currentMemory.embed_data.mediaUrl}
              style={{
                maxWidth: '80%',
                maxHeight: '80%',
                boxShadow: '0 8px 20px rgba(0,0,0,0.25)'
              }}
            />
          )}
        </Box>
      );
    }
    
    // For text only memories
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={12}
          sx={{
            p: 5,
            borderRadius: 4,
            maxWidth: '80%',
            backgroundColor: theme.palette.mode === 'dark' 
              ? 'rgba(40, 40, 40, 0.9)' 
              : 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Typography 
            variant="h4" 
            component="div" 
            gutterBottom
            align="center"
            sx={{ 
              fontStyle: 'italic',
              fontWeight: 'light',
              color: theme.palette.primary.main
            }}
          >
            {currentMemory.content || 'R1 Memory'}
          </Typography>
          
          {currentMemory.hashtags && Array.isArray(currentMemory.hashtags) && currentMemory.hashtags.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, mt: 3 }}>
              {currentMemory.hashtags.map(tag => (
                <Chip
                  key={tag}
                  label={`#${tag}`}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          )}
          
          {currentMemory.url && (
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              <a 
                href={currentMemory.url} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: theme.palette.primary.main }}
              >
                {currentMemory.url}
              </a>
            </Typography>
          )}
        </Paper>
      </Box>
    );
  };
  
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box sx={{ textAlign: 'center', py: 8 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }
  
  if (memories.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 8 }}>
        <Typography variant="h6" gutterBottom>
          No memories yet!
        </Typography>
        <Typography variant="body1">
          Be the first to share a memory about Rabbit R1 using the upload form above.
        </Typography>
      </Box>
    );
  }
  
  return (
    <>
      <Box sx={{ mb: 4 }}>
        {activeHashtags.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Filtering by hashtags:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              {activeHashtags.map(tag => (
                <Chip
                  key={tag}
                  label={`#${tag}`}
                  onDelete={() => handleHashtagClick(tag)}
                  color="primary"
                />
              ))}
              <Button 
                variant="text" 
                size="small" 
                onClick={clearHashtagFilters}
                sx={{ ml: 1 }}
              >
                Clear Filters
              </Button>
            </Box>
          </Box>
        )}
        
        {renderRandomSlideshowButton()}
        
        {filteredMemories.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="body1">
              No memories match your selected hashtags. Try selecting different ones.
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {filteredMemories.map(memory => (
              <Grid item xs={12} sm={6} md={4} key={memory.id}>
                <MemoryCard 
                  memory={memory} 
                  onHashtagClick={handleHashtagClick}
                  onOpenInSlideshow={handleOpenSlideshow}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      
      {/* Slideshow Dialog */}
      <Dialog
        fullScreen
        open={slideshowOpen}
        onClose={handleCloseSlideshow}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#f5f5f5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseSlideshow}
            aria-label="close"
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              zIndex: 10,
              bgcolor: 'rgba(0,0,0,0.3)',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.5)',
              }
            }}
          >
            <FullscreenExitIcon />
          </IconButton>
          
          {renderSlideContent()}
          {renderSlideshowControls()}
        </Box>
      </Dialog>
    </>
  );
};

export default MemoryGrid;
