import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Card, 
  CardContent, 
  Typography, 
  Chip,
  LinearProgress,
  Alert,
  IconButton,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Divider,
  ButtonGroup
} from '@mui/material';
import { 
  Send as SendIcon, 
  Tag as TagIcon, 
  Clear as ClearIcon, 
  Person as PersonIcon,
  Twitter as TwitterIcon, 
  Reddit as RedditIcon, 
  Instagram as InstagramIcon,
  Link as LinkIcon,
  Attachment as AttachmentIcon
} from '@mui/icons-material';
import { createMemory, getWeeklyPostCount, getUserByFingerprint, createUser, updateUsername } from '../../api/supabase';
import { getUrlMetadata } from '../../api/urlEmbed';
import { checkContentForR1Relevance } from '../../api/moderation';
import { createUserFingerprint } from '../../utils/ipAddress';

const MAX_WEEKLY_POSTS = 35;
const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const MAX_CONTENT_LENGTH = 2000; // Maximum characters allowed in a post
const MAX_TOKENS = 500; // Maximum tokens allowed in a post

// Simple function to approximate token count - OpenAI typically uses ~4 chars per token
const estimateTokenCount = (text: string): number => {
  return Math.ceil(text.length / 4);
};

const UploadBar: React.FC = () => {
  const [content, setContent] = useState('');
  const [url, setUrl] = useState('');
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [currentHashtag, setCurrentHashtag] = useState('');
  const [weeklyPostCount, setWeeklyPostCount] = useState(0);
  const [userIp, setUserIp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [charCount, setCharCount] = useState(0);
  const [tokenCount, setTokenCount] = useState(0);
  const [username, setUsername] = useState<string>('');
  const [hasUsername, setHasUsername] = useState<boolean>(false);
  const [showUsernameDialog, setShowUsernameDialog] = useState<boolean>(false);
  const [newUsername, setNewUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  
  // Get user IP and weekly post count on mount
  useEffect(() => {
    const initialize = async () => {
      try {
        const fingerprint = await createUserFingerprint();
        setUserIp(fingerprint);
        
        const count = await getWeeklyPostCount(fingerprint);
        setWeeklyPostCount(count);

        // Check if user has a username
        const user = await getUserByFingerprint(fingerprint);
        if (user && user.username) {
          setUsername(user.username);
          setHasUsername(true);
        }
      } catch (error) {
        console.error('Error initializing UploadBar:', error);
        setError('Unable to initialize. Please try refreshing the page.');
      }
    };
    
    initialize();
  }, []);
  
  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
    setCharCount(e.target.value.length);
    setTokenCount(estimateTokenCount(e.target.value));
    
    // Check if content contains URLs
    const urlMatch = e.target.value.match(URL_REGEX);
    if (urlMatch && urlMatch[0] && urlMatch[0] !== url) {
      setUrl(urlMatch[0]);
    } else if (!urlMatch && url) {
      setUrl('');
    }
  };
  
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };
  
  const handleHashtagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove # if user typed it
    const value = e.target.value.startsWith('#') 
      ? e.target.value.substring(1) 
      : e.target.value;
    
    setCurrentHashtag(value);
  };
  
  const handleAddHashtag = () => {
    if (currentHashtag && !hashtags.includes(currentHashtag)) {
      setHashtags([...hashtags, currentHashtag]);
      setCurrentHashtag('');
    }
  };
  
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && currentHashtag) {
      e.preventDefault();
      handleAddHashtag();
    }
  };
  
  const removeHashtag = (tagToRemove: string) => {
    setHashtags(hashtags.filter(tag => tag !== tagToRemove));
  };
  
  const handleSubmit = async () => {
    // Validate inputs
    if (!content && !url) {
      setError('Please enter some content or a URL.');
      return;
    }
    
    if (weeklyPostCount >= MAX_WEEKLY_POSTS) {
      setError(`You've reached your weekly post limit of ${MAX_WEEKLY_POSTS}. Please try again next week.`);
      return;
    }

    // Check content length and token limit
    if (content.length > MAX_CONTENT_LENGTH) {
      setError(`Your post is too long. Maximum allowed length is ${MAX_CONTENT_LENGTH} characters.`);
      return;
    }

    const tokenCount = estimateTokenCount(content);
    if (tokenCount > MAX_TOKENS) {
      setError(`Your post exceeds the maximum token limit of ${MAX_TOKENS}. Please shorten your message.`);
      return;
    }
    
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Check if content is related to Rabbit R1
      const contentToCheck = content || url;
      const moderationResult = await checkContentForR1Relevance(contentToCheck, url);
      
      if (!moderationResult.isApproved) {
        setError(`Your post was not approved: ${moderationResult.reason || 'Content must be related to Rabbit R1.'}`);
        setIsLoading(false);
        return;
      }
      
      // Get URL metadata if URL is provided
      let embedData = undefined;
      if (url) {
        embedData = await getUrlMetadata(url);
      }
      
      // Create the memory
      await createMemory({
        content,
        url: url || undefined,
        hashtags,
        embed_data: embedData,
        user_ip: userIp,
        username: username || undefined
      });
      
      // Update weekly post count
      setWeeklyPostCount(prev => prev + 1);
      
      // Reset form
      setContent('');
      setUrl('');
      setHashtags([]);
      
      setSuccess('Your memory has been posted!');
    } catch (error) {
      console.error('Error posting memory:', error);
      setError('An error occurred while posting your memory. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleUsernameDialogOpen = () => {
    setNewUsername(username || '');
    setUsernameError(null);
    setShowUsernameDialog(true);
  };

  const handleUsernameDialogClose = () => {
    setShowUsernameDialog(false);
    setUsernameError(null);
  };

  const handleSetUsername = async () => {
    if (!newUsername.trim()) {
      setUsernameError('Username cannot be empty');
      return;
    }

    if (newUsername.length > 30) {
      setUsernameError('Username must be 30 characters or less');
      return;
    }

    // Check for valid characters (letters, numbers, underscores, hyphens)
    if (!/^[a-zA-Z0-9_-]+$/.test(newUsername)) {
      setUsernameError('Username can only contain letters, numbers, underscores, and hyphens');
      return;
    }

    try {
      const fingerprint = await createUserFingerprint();
      
      // Check if user already exists
      const existingUser = await getUserByFingerprint(fingerprint);
      
      if (existingUser) {
        // Update username if user exists
        await updateUsername(existingUser.id, newUsername);
      } else {
        // Create new user if doesn't exist
        await createUser(fingerprint, newUsername);
      }
      
      setUsername(newUsername);
      setHasUsername(true);
      setShowUsernameDialog(false);
      setSuccess('Username has been set!');
    } catch (error) {
      console.error('Error setting username:', error);
      setUsernameError('Failed to set username. Please try again later.');
    }
  };
  
  const addSocialMediaLink = (platform: string) => {
    let prefix = '';
    
    switch (platform) {
      case 'twitter':
        prefix = 'https://x.com/';
        break;
      case 'reddit':
        prefix = 'https://reddit.com/r/';
        break;
      case 'instagram':
        prefix = 'https://instagram.com/p/';
        break;
      case 'discord':
        prefix = 'https://cdn.discordapp.com/attachments/';
        break;
      default:
        prefix = 'https://';
    }
    
    setUrl(prefix);
  };
  
  const remainingPosts = MAX_WEEKLY_POSTS - weeklyPostCount;
  const progressValue = (weeklyPostCount / MAX_WEEKLY_POSTS) * 100;
  
  return (
    <Card elevation={3} sx={{ mb: 4, overflow: 'visible' }}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom sx={{ color: 'primary.main' }}>
          Share Your R1 Memory
        </Typography>
        
        {/* Weekly Post Counter */}
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Weekly Post Limit
            </Typography>
            <Typography variant="body2" color="primary">
              {remainingPosts} of {MAX_WEEKLY_POSTS} remaining
            </Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={progressValue} 
            sx={{ height: 8, borderRadius: 4 }}
          />
        </Box>
        
        {/* Content Input */}
        <TextField
          label="Share your memory or thought about Rabbit R1"
          multiline
          rows={3}
          fullWidth
          value={content}
          onChange={handleContentChange}
          variant="outlined"
          disabled={isLoading}
          sx={{ mb: 2 }}
          helperText={`${charCount}/${MAX_CONTENT_LENGTH} chars (${tokenCount}/${MAX_TOKENS} tokens)`}
          error={charCount > MAX_CONTENT_LENGTH || tokenCount > MAX_TOKENS}
        />
        
        {/* URL Input */}
        <TextField
          label="URL (optional)"
          fullWidth
          value={url}
          onChange={handleUrlChange}
          variant="outlined"
          disabled={isLoading}
          sx={{ mb: 2 }}
          placeholder="https://example.com"
          InputProps={{
            endAdornment: url ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setUrl('')}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        
        {/* Social Media Quick Links */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <ButtonGroup variant="contained" aria-label="social media quick links">
            <Button onClick={() => addSocialMediaLink('twitter')} startIcon={<TwitterIcon />}>Twitter</Button>
            <Button onClick={() => addSocialMediaLink('reddit')} startIcon={<RedditIcon />}>Reddit</Button>
            <Button onClick={() => addSocialMediaLink('instagram')} startIcon={<InstagramIcon />}>Instagram</Button>
            <Button onClick={() => addSocialMediaLink('discord')} startIcon={<AttachmentIcon />}>Discord</Button>
          </ButtonGroup>
        </Box>
        
        {/* Hashtags Input */}
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Add hashtags"
            value={currentHashtag}
            onChange={handleHashtagChange}
            onKeyDown={handleKeyDown}
            disabled={isLoading}
            variant="outlined"
            placeholder="Enter a hashtag"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TagIcon color="primary" />
                </InputAdornment>
              ),
              endAdornment: currentHashtag ? (
                <InputAdornment position="end">
                  <Button 
                    onClick={handleAddHashtag}
                    variant="text"
                    size="small"
                  >
                    Add
                  </Button>
                </InputAdornment>
              ) : null,
            }}
          />
          
          {/* Hashtag List */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1, gap: 1 }}>
            {hashtags.map(tag => (
              <Chip
                key={tag}
                label={`#${tag}`}
                onDelete={() => removeHashtag(tag)}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
        
        {/* Error and Success Messages */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}
        
        {/* Submit Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
            onClick={handleSubmit}
            disabled={isLoading || (!content && !url) || weeklyPostCount >= MAX_WEEKLY_POSTS}
            sx={{ 
              borderRadius: 8,
              px: 3,
              py: 1
            }}
          >
            {isLoading ? 'Posting...' : 'Post Memory'}
          </Button>
        </Box>
        
        {/* Username Dialog */}
        <Dialog open={showUsernameDialog} onClose={handleUsernameDialogClose}>
          <DialogTitle>Set Your Username</DialogTitle>
          <DialogContent>
            <TextField
              label="Username"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              variant="outlined"
              fullWidth
              error={!!usernameError}
              helperText={usernameError}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUsernameDialogClose}>Cancel</Button>
            <Button onClick={handleSetUsername}>Set Username</Button>
          </DialogActions>
        </Dialog>
        
        {/* Username Button */}
        <Box sx={{ mt: 2 }}>
          {hasUsername ? (
            <Typography variant="body2" color="text.secondary">
              Your username is: {username}
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUsernameDialogOpen}
              sx={{ 
                borderRadius: 8,
                px: 3,
                py: 1
              }}
            >
              Set Username
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default UploadBar;
