import axios from 'axios';

interface ModerationResponse {
  id: string;
  model: string;
  results: {
    flagged: boolean;
    categories: {
      [key: string]: boolean;
    };
    category_scores: {
      [key: string]: number;
    };
  }[];
}

/**
 * Check if content is appropriate using OpenAI's moderation API
 * @param content Text content to check
 * @returns Moderation result from OpenAI
 */
export const moderateContent = async (content: string): Promise<ModerationResponse> => {
  try {
    const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
    
    if (!OPENAI_API_KEY) {
      throw new Error('OpenAI API key is not set in environment variables');
    }
    
    const response = await axios.post(
      'https://api.openai.com/v1/moderations',
      { input: content },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        }
      }
    );
    
    return response.data as ModerationResponse;
  } catch (error) {
    console.error('Error with OpenAI moderation:', error);
    throw error;
  }
};

/**
 * Checks if content is appropriate for posting using OpenAI moderation
 * @param content The content to moderate
 * @returns A result object with flagged status and details
 */
export const isContentAppropriate = async (content: string): Promise<{
  appropriate: boolean;
  moderationResult: ModerationResponse | null;
  message: string;
}> => {
  try {
    const moderationResult = await moderateContent(content);
    
    if (moderationResult.results[0].flagged) {
      // Content was flagged as inappropriate
      return {
        appropriate: false,
        moderationResult,
        message: 'Content failed moderation check'
      };
    }
    
    return {
      appropriate: true,
      moderationResult,
      message: 'Content passed moderation check'
    };
  } catch (error) {
    console.error('Error checking content appropriateness:', error);
    
    // If there's an error with the moderation API, we'll allow the content
    // but log the error and notify admins
    return {
      appropriate: true, // Default to allowing if moderation fails
      moderationResult: null,
      message: 'Moderation check failed, content approved by default'
    };
  }
};
