import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppThemeProvider } from './context/ThemeContext';
import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import ArticlesPage from './pages/ArticlesPage';

import './App.css';

function App() {
  return (
    <AppThemeProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/articles" element={<ArticlesPage />} />
            <Route path="/admin" element={<AdminPage />} />
          </Routes>
        </Layout>
      </Router>
    </AppThemeProvider>
  );
}

export default App;
