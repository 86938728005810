import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || '';

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Supabase credentials are missing');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Types for our database records
export interface Memory {
  id: string;
  created_at: string;
  content: string;
  url?: string;
  hashtags: string[];
  embed_data?: UrlEmbedData;
  user_ip: string;
  username?: string; // New field for user tags
  likes: number | null; // Update likes property to be number | null
}

export interface UrlEmbedData {
  title?: string;
  description?: string;
  image?: string;
  favicon?: string;
  mediaType?: 'image' | 'video' | 'audio' | 'link';
  mediaUrl?: string;
  source?: 'discord' | 'twitter' | 'reddit' | 'instagram'; // Source platform
  author?: string; // Author/username of social media content
  publishedAt?: string; // Publication date if available
}

export interface Announcement {
  id: string;
  created_at: string;
  title: string;
  content: string;
  read_by: string[] | null;
}

export interface Article {
  id: string;
  created_at: string;
  title: string;
  url: string;
  source: string;
  description: string;
  image_url?: string;
  approved: boolean;
  submitted_by: string;  // User IP of submitter
  moderation_result?: {
    appropriate: boolean;
    moderation_result?: any;
    message?: string;
    r1_relevant?: boolean;
  };
}

export interface User {
  id: string;
  created_at: string;
  fingerprint: string;
  username: string;
}

// Memory Functions
export const getMemories = async () => {
  const { data, error } = await supabase
    .from('memories')
    .select('id, created_at, content, url, hashtags, embed_data, user_ip, username, likes')
    .order('created_at', { ascending: false });
  
  if (error) throw error;
  
  // Ensure likes is always a number
  const processedData = data?.map(memory => ({
    ...memory,
    likes: memory.likes === null ? 0 : memory.likes
  })) || [];
  
  return processedData as Memory[];
};

export const createMemory = async (memory: Omit<Memory, 'id' | 'created_at' | 'likes'>) => {
  const { data, error } = await supabase
    .from('memories')
    .insert([{ ...memory, likes: 0 }])
    .select('id, created_at, content, url, hashtags, embed_data, user_ip, username, likes');
  
  if (error) throw error;
  return data[0] as Memory;
};

export const getWeeklyPostCount = async (userIp: string) => {
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  
  const { count, error } = await supabase
    .from('memories')
    .select('id', { count: 'exact', head: true })
    .eq('user_ip', userIp)
    .gte('created_at', oneWeekAgo.toISOString());
  
  if (error) throw error;
  return count || 0;
};

export const updateMemoryLikes = async (id: string, likes: number) => {
  console.log(`Updating memory ${id} with likes: ${likes}`);
  
  try {
    // First check current memory to debug
    const { data: current, error: fetchError } = await supabase
      .from('memories')
      .select('id, likes')
      .eq('id', id)
      .single();
    
    if (fetchError) {
      console.error('Error fetching current memory:', fetchError);
      throw fetchError;
    }
    
    console.log('Current memory data:', current);
    
    // Now update
    const { data, error } = await supabase
      .from('memories')
      .update({ likes })
      .eq('id', id)
      .select('id, created_at, content, url, hashtags, embed_data, user_ip, username, likes');
    
    if (error) {
      console.error('Error updating memory likes:', error);
      throw error;
    }
    
    console.log('Updated memory data:', data);
    return data[0] as Memory;
  } catch (err) {
    console.error('Failed to update memory likes:', err);
    throw err;
  }
};

export const deleteMemory = async (id: string) => {
  try {
    console.log('Attempting to delete memory with ID:', id);
    
    const { data, error } = await supabase
      .from('memories')
      .delete()
      .eq('id', id)
      .select();
    
    if (error) {
      console.error('Supabase delete error:', error);
      throw error;
    }
    
    console.log('Delete result:', data);
    return true;
  } catch (err) {
    console.error('Failed to delete memory:', err);
    throw err;
  }
};

export const deleteAllMemories = async () => {
  // Use with caution - this deletes all memories
  try {
    const { data, error } = await supabase
      .from('memories')
      .delete()
      .gte('id', '00000000-0000-0000-0000-000000000000') // Will match all UUIDs
      .select();
    
    if (error) {
      console.error('Supabase delete error:', error);
      throw error;
    }
    
    console.log('Deleted memories result:', data);
    return true;
  } catch (err) {
    console.error('Failed to delete memories:', err);
    throw err;
  }
};

// Announcement Functions
export const getAnnouncements = async () => {
  const { data, error } = await supabase
    .from('announcements')
    .select('*')
    .order('created_at', { ascending: false });
  
  if (error) throw error;
  return data as Announcement[];
};

export const createAnnouncement = async (announcement: Omit<Announcement, 'id' | 'created_at' | 'read_by'>) => {
  const { data, error } = await supabase
    .from('announcements')
    .insert([{ ...announcement, read_by: [] }])
    .select();
  
  if (error) throw error;
  return data[0] as Announcement;
};

export const markAnnouncementAsRead = async (id: string, userIp: string) => {
  console.log(`Marking announcement ${id} as read for user IP: ${userIp}`);
  
  try {
    // First, get the current announcement to access its read_by array
    const { data: announcement, error: fetchError } = await supabase
      .from('announcements')
      .select('*')
      .eq('id', id)
      .single();
    
    if (fetchError) {
      console.error('Error fetching announcement:', fetchError);
      throw fetchError;
    }
    
    console.log('Current announcement data:', announcement);
    
    // Add the user's IP to the read_by array if it's not already there
    const readBy = Array.isArray(announcement.read_by) ? [...announcement.read_by] : [];
    console.log('Current read_by array:', readBy);
    
    if (!readBy.includes(userIp)) {
      readBy.push(userIp);
      console.log('Updated read_by array:', readBy);
      
      // Update the announcement with the new read_by array
      const { data, error } = await supabase
        .from('announcements')
        .update({ read_by: readBy })
        .eq('id', id)
        .select();
      
      if (error) {
        console.error('Error updating announcement read_by:', error);
        throw error;
      }
      
      console.log('Update response from Supabase:', data);
      return data[0] as Announcement;
    } else {
      console.log('User IP already in read_by array, no update needed');
    }
    
    return announcement as Announcement;
  } catch (err) {
    console.error('Failed to mark announcement as read:', err);
    throw err;
  }
};

// Article Functions
export const getArticles = async (approvedOnly = true) => {
  let query = supabase
    .from('articles')
    .select('*')
    .order('created_at', { ascending: false });
  
  if (approvedOnly) {
    query = query.eq('approved', true);
  }
  
  const { data, error } = await query;
  
  if (error) throw error;
  return data as Article[];
};

export const getArticleById = async (id: string) => {
  const { data, error } = await supabase
    .from('articles')
    .select('*')
    .eq('id', id)
    .single();
  
  if (error) throw error;
  return data as Article;
};

export const submitArticle = async (article: Omit<Article, 'id' | 'created_at' | 'approved'>) => {
  // Articles are auto-approved if they're related to Rabbit R1 and pass moderation
  const isR1Related = article.moderation_result?.r1_relevant === true;
  const isAppropriate = article.moderation_result?.appropriate === true;
  const isAutoApproved = isR1Related && isAppropriate;
  
  const { data, error } = await supabase
    .from('articles')
    .insert([{ 
      title: article.title, 
      url: article.url, 
      source: article.source, 
      description: article.description, 
      image_url: article.image_url, 
      submitted_by: article.submitted_by, 
      moderation_result: article.moderation_result, 
      approved: isAutoApproved 
    }])
    .select('*');
  
  if (error) {
    console.error('Error submitting article:', error);
    throw error;
  }
  
  return data[0] as Article;
};

export const approveArticle = async (id: string) => {
  const { data, error } = await supabase
    .from('articles')
    .update({ approved: true })
    .eq('id', id)
    .select('*');
  
  if (error) {
    console.error('Error approving article:', error);
    throw error;
  }
  
  return data[0] as Article;
};

export const deleteArticle = async (id: string) => {
  try {
    const { data, error } = await supabase
      .from('articles')
      .delete()
      .eq('id', id)
      .select();
    
    if (error) {
      console.error('Error deleting article:', error);
      throw error;
    }
    
    console.log('Deleted article:', data);
    return true;
  } catch (err) {
    console.error('Failed to delete article:', err);
    throw err;
  }
};

export const deleteAllArticles = async () => {
  try {
    const { data, error } = await supabase
      .from('articles')
      .delete()
      .not('id', 'is', null)
      .select();
    
    if (error) {
      console.error('Error deleting all articles:', error);
      throw error;
    }
    
    console.log('Deleted all articles:', data);
    return true;
  } catch (err) {
    console.error('Failed to delete all articles:', err);
    throw err;
  }
};

// User Functions
export const getUserByFingerprint = async (fingerprint: string) => {
  const { data, error } = await supabase
    .from('users')
    .select('*')
    .eq('fingerprint', fingerprint)
    .single();
  
  if (error && error.code !== 'PGRST116') {
    console.error('Error fetching user:', error);
    return null;
  }
  
  return data as User | null;
};

export const createUser = async (fingerprint: string, username: string) => {
  const { data, error } = await supabase
    .from('users')
    .insert([{ fingerprint, username }])
    .select('*')
    .single();
  
  if (error) {
    console.error('Error creating user:', error);
    throw error;
  }
  
  return data as User;
};

export const updateUsername = async (id: string, username: string) => {
  const { data, error } = await supabase
    .from('users')
    .update({ username })
    .eq('id', id)
    .select('*')
    .single();
  
  if (error) {
    console.error('Error updating username:', error);
    throw error;
  }
  
  return data as User;
};
