import React, { useState, useContext, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Badge, 
  Menu, 
  MenuItem, 
  ListItemText, 
  Box, 
  Drawer, 
  List, 
  ListItem,
  Button
} from '@mui/material';
import { 
  Brightness4 as DarkIcon, 
  Brightness7 as LightIcon, 
  Notifications as NotificationsIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { supabase, Announcement, markAnnouncementAsRead } from '../../api/supabase';
import { createUserFingerprint } from '../../utils/ipAddress';

const Header: React.FC = () => {
  const { mode, toggleColorMode } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [userIp, setUserIp] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Fetch the user's IP/fingerprint on component mount
  useEffect(() => {
    const getFingerprint = async () => {
      const fingerprint = await createUserFingerprint();
      setUserIp(fingerprint);
    };
    
    getFingerprint();
  }, []);

  // Fetch announcements
  useEffect(() => {
    if (!userIp) return;
    
    const fetchAnnouncements = async () => {
      try {
        const { data } = await supabase
          .from('announcements')
          .select('*')
          .order('created_at', { ascending: false });
        
        if (data) {
          const announcements = data as Announcement[];
          setAnnouncements(announcements);
          
          // Count unread announcements - fix null check here
          const unread = announcements.filter(a => {
            // Ensure read_by is treated as an empty array if null/undefined
            const readBy = a.read_by || [];
            return !readBy.includes(userIp);
          }).length;
          
          setUnreadCount(unread);
        }
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };
    
    fetchAnnouncements();
    
    // Subscribe to changes in the announcements table
    const subscription = supabase
      .channel('announcements-channel')
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'announcements' 
      }, fetchAnnouncements)
      .subscribe();
    
    return () => {
      subscription.unsubscribe();
    };
  }, [userIp]);

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const handleReadAnnouncement = async (id: string) => {
    if (!userIp) return;
    
    try {
      await markAnnouncementAsRead(id, userIp);
      
      // Refresh announcements from the database instead of only updating local state
      const { data } = await supabase
        .from('announcements')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (data) {
        const refreshedAnnouncements = data as Announcement[];
        setAnnouncements(refreshedAnnouncements);
        
        // Recount unread announcements
        const unread = refreshedAnnouncements.filter(a => {
          const readBy = a.read_by || [];
          return !readBy.includes(userIp);
        }).length;
        
        setUnreadCount(unread);
      }
    } catch (error) {
      console.error('Error marking announcement as read:', error);
    }
  };

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ mr: 4 }}>
            r1memories
          </Typography>
          
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <Button 
              color="inherit" 
              component={RouterLink} 
              to="/"
              sx={{ mr: 2 }}
            >
              Home
            </Button>
            <Button 
              color="inherit" 
              component={RouterLink} 
              to="/articles"
            >
              Articles
            </Button>
          </Box>
          
          <IconButton 
            color="inherit" 
            onClick={handleNotificationsClick}
            aria-label="notifications"
          >
            <Badge badgeContent={unreadCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          
          <IconButton 
            color="inherit" 
            onClick={toggleColorMode} 
            aria-label="toggle theme"
          >
            {mode === 'dark' ? <LightIcon /> : <DarkIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationsClose}
        sx={{ mt: 1 }}
      >
        {announcements.length === 0 ? (
          <MenuItem>
            <ListItemText>No announcements</ListItemText>
          </MenuItem>
        ) : (
          announcements.slice(0, 5).map((announcement) => {
            // Safely check if userIp is in read_by array
            const readBy = Array.isArray(announcement.read_by) ? announcement.read_by : [];
            const isRead = readBy.includes(userIp);
            
            return (
              <MenuItem 
                key={announcement.id}
                onClick={() => {
                  handleReadAnnouncement(announcement.id);
                  toggleDrawer(true);
                  handleNotificationsClose();
                }}
                sx={{
                  backgroundColor: isRead ? 'inherit' : 'rgba(103, 58, 183, 0.1)',
                  fontWeight: isRead ? 'normal' : 'bold',
                }}
              >
                <ListItemText 
                  primary={announcement.title}
                  secondary={new Date(announcement.created_at).toLocaleDateString()}
                />
              </MenuItem>
            );
          })
        )}
        {announcements.length > 5 && (
          <MenuItem onClick={() => {
            toggleDrawer(true);
            handleNotificationsClose();
          }}>
            <ListItemText primary={`View all (${announcements.length})`} />
          </MenuItem>
        )}
      </Menu>
      
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Box
          sx={{
            width: 350,
            p: 2,
            height: '100%',
            overflow: 'auto'
          }}
          role="presentation"
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Announcements</Typography>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          
          <List>
            {announcements.map((announcement) => {
              // Safely check if userIp is in read_by array
              const readBy = Array.isArray(announcement.read_by) ? announcement.read_by : [];
              const isRead = readBy.includes(userIp);
              
              return (
                <ListItem 
                  key={announcement.id}
                  sx={{
                    mb: 2,
                    backgroundColor: isRead ? 'inherit' : 'rgba(103, 58, 183, 0.1)',
                    borderRadius: 1,
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: 'rgba(103, 58, 183, 0.05)',
                    }
                  }}
                  onClick={() => handleReadAnnouncement(announcement.id)}
                >
                  <Box width="100%">
                    <Typography 
                      variant="subtitle1" 
                      fontWeight={isRead ? 'normal' : 'bold'}
                    >
                      {announcement.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      {new Date(announcement.created_at).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1">
                      {announcement.content}
                    </Typography>
                  </Box>
                </ListItem>
              );
            })}
            
            {announcements.length === 0 && (
              <ListItem>
                <Typography>No announcements yet</Typography>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
