import axios from 'axios';

// Extract video ID from YouTube URL
const extractVideoId = (url: string): string | null => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : null;
};

// Function to fetch YouTube transcript
export const getYoutubeTranscript = async (url: string): Promise<string> => {
  try {
    const videoId = extractVideoId(url);
    
    if (!videoId) {
      throw new Error('Invalid YouTube URL');
    }
    
    // We'll use a public API that extracts YouTube transcripts
    // In a production environment, you might want to implement this on your backend
    const response = await axios.get(`https://yt-transcript-api.fly.dev/transcript?videoId=${videoId}`);
    
    if (response.status !== 200) {
      throw new Error('Failed to fetch transcript');
    }
    
    // The API returns an array of transcript segments with text and timestamps
    const transcriptSegments = response.data.transcript;
    
    if (!transcriptSegments || transcriptSegments.length === 0) {
      throw new Error('No transcript available for this video');
    }
    
    // Join all segments into a single string
    return transcriptSegments.map((segment: any) => segment.text).join(' ');
    
  } catch (error) {
    console.error('Error fetching YouTube transcript:', error);
    throw error;
  }
};
