import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Typography, 
  Box, 
  CircularProgress,
  Alert,
  Paper
} from '@mui/material';
import { Article, getArticles, supabase } from '../../api/supabase';
import ArticleCard from './ArticleCard';

const ArticleGrid: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch articles
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        const data = await getArticles(true); // Only get approved articles
        setArticles(data);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setError('Failed to load articles. Please try refreshing the page.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchArticles();
    
    // Set up real-time subscription for articles
    const subscription = supabase
      .channel('public:articles')
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'articles' 
      }, (payload) => {
        console.log('Real-time update for articles received:', payload);
        fetchArticles();
      })
      .subscribe();
      
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>
    );
  }

  if (articles.length === 0) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center', my: 2 }}>
        <Typography variant="body1" color="text.secondary">
          No articles have been published yet. You can be the first to share one!
        </Typography>
      </Paper>
    );
  }

  return (
    <Grid container spacing={3}>
      {articles.map((article) => (
        <Grid item xs={12} sm={6} md={4} key={article.id}>
          <ArticleCard article={article} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ArticleGrid;
