import React from 'react';
import { Box, Container, Typography, Link, Button } from '@mui/material';
import { SvgIcon } from '@mui/material';

// Patreon Icon SVG
const PatreonIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M14.82 2.41c3.96 0 7.18 3.24 7.18 7.22 0 3.97-3.22 7.2-7.18 7.2-3.97 0-7.19-3.23-7.19-7.2 0-3.98 3.22-7.22 7.19-7.22M2 21.6h3.5V2.41H2V21.6z"
    />
  </SvgIcon>
);

const Footer: React.FC = () => {
  const patreonLink = process.env.REACT_APP_PATREON_LINK || 'https://www.patreon.com/Foxplaid19773';
  
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[900],
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} r1memories - A community site for Rabbit R1 enthusiasts
          </Typography>
          
          <Box sx={{ mt: { xs: 2, sm: 0 } }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PatreonIcon />}
              href={patreonLink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                borderRadius: 4,
                px: 2,
                py: 1,
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              Support on Patreon
            </Button>
          </Box>
        </Box>
        
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="caption" color="text.secondary">
            Not affiliated with Rabbit Inc. This is a fan-made community site.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
